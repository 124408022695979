const DEFAULT_OPTIONS = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

export default class Numbers {
  static _locale;
  static _decimalSeparator;
  static _groupSeparator;
  static _zeroDecimalPart;

  static get locale() {
    if (this._locale) {
      return this._locale;
    }

    this._locale = document.documentElement.lang;

    return this._locale;
  }

  static get decimalSeparator() {
    if (this._decimalSeparator) {
      return this._decimalSeparator;
    }

    this._decimalSeparator = new Intl.NumberFormat(this.locale)
      .formatToParts(1.1)
      .find(part => part.type === 'decimal')
      .value;

    return this._decimalSeparator;
  }

  static get groupSeparator() {
    if (this._groupSeparator) {
      return this._groupSeparator;
    }

    this._groupSeparator = new Intl.NumberFormat(this.locale)
      // bulgarian uses group separator for numbers larger than 9999
      .formatToParts(10000)
      .find(part => part.type === 'group')
      .value;

    // In some cases group separator might contain non-breaking space or narrow non-breaking space.
    // In order to parse/format numbers correctly we replace any whitespaces characters with regular space.
    if (/\s/.test(this._groupSeparator)) {
      this._groupSeparator = ' ';
    }

    return this._groupSeparator;
  }

  static get zeroDecimalPart() {
    if (this._zeroDecimalPart) {
      return this._zeroDecimalPart;
    }

    this._zeroDecimalPart = new RegExp('\\' + this.decimalSeparator + '0+$');

    return this._zeroDecimalPart;
  }

  static format(value, options) {
    if (typeof value !== 'number') {
      return value;
    }

    options = Object.assign({}, DEFAULT_OPTIONS, options);

    const formattedValue = new Intl.NumberFormat(this.locale, options).format(value);

    return formattedValue
      .replace(this.zeroDecimalPart, '')
      // In order to format numbers correctly we replace whitespace character with non-breaking space.
      .replace(/\s/g, '\u00A0');
  }

  static parse(value) {
    if (typeof value !== 'string') {
      return null;
    }

    // In DecimalFormats.create() if group separator is a regular space we replace it with non-breaking space.
    // Most likely the reason for that was to display price inline in document templates.
    // In order to parse numbers correctly we replace any whitespaces characters with regular space.
    value = value.replaceAll(/\s/g, ' ');
    value = value.replaceAll(this.groupSeparator, '');
    value = value.replaceAll(this.decimalSeparator, '.');

    const number = Number.parseFloat(value);

    if (Number.isNaN(number)) {
      return null;
    }

    return number;
  }

  static round(value) {
    if (typeof value !== 'number') {
      return value;
    }

    // per https://stackoverflow.com/a/11832950
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  static clamp(value, min, max) {
    if (typeof value !== 'number') {
      return value;
    }

    if (value < min) {
      return min;
    }

    if (value > max) {
      return max;
    }

    return value;
  }
}
