<script setup>
import { autoUpdate, flip, limitShift, offset, shift } from '@floating-ui/dom';
import { arrow, useFloating } from '@floating-ui/vue';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';

const DEFAULT_PLACEMENT = 'top';
const AVAILABLE_PLACEMENTS = [
  'top', 'top-start', 'top-end',
  'bottom', 'bottom-start', 'bottom-end',
  'left', 'left-start', 'left-end',
  'right', 'right-start', 'right-end'
];

const TARGET_OFFSET = 4;
const VIEWPORT_PADDING = 8;

class TooltipTriggers {
  active = new Set();

  handleEvent(event) {
    switch (event.type) {
      case 'mouseenter':
        this.active.add('hover');
        break;
      case 'mouseleave':
        this.active.delete('hover');
        break;
      case 'focusin':
        this.active.add('focus');
        break;
      case 'focusout':
        this.active.delete('focus');
        break;
    }
  }

  isActive() {
    return this.active.size > 0;
  }

  deactivate() {
    this.active.clear();
  }
}

class TooltipContent {
  nodes = [];

  fromSlot(slot) {
    if (this.nodes.length > 0) {
      return;
    }

    this.nodes = slot.assignedNodes();
  }

  appendTo(target) {
    this.nodes.forEach(node => target.append(node));
  }

  reset() {
    this.nodes = [];
  }
}

defineOptions({
  inheritAttrs: false
});

const props = defineProps({
  target: {
    type: [String, HTMLElement],
    default: 'parent'
  },
  placement: {
    type: String,
    default: DEFAULT_PLACEMENT
  },
  style: String,
  disabled: Boolean
});

const triggers = new TooltipTriggers();
const content = new TooltipContent();

const visible = ref(false);
const slot = ref(null);
const host = ref(null);

const target = computed(() => getTarget(props.target, host.value));
const targetObserver = new MutationObserver(() => {
  if (isTargetDisabled(target.value)) {
    onDisabled();
  }
});

const tooltip = ref(null);
const tooltipArrow = ref(null);
const tooltipContent = ref(null);

const preferredPlacement = computed(() => getPlacement(props.placement));
const fallbackPlacements = computed(() => getFallbackPlacements(props.placement));

const middleware = computed(() => ([
  offset(TARGET_OFFSET),
  shift({
    limiter: limitShift(),
    padding: VIEWPORT_PADDING
  }),
  flip({
    fallbackPlacements: fallbackPlacements.value,
    padding: VIEWPORT_PADDING
  }),
  arrow({
    element: tooltipArrow,
    padding: VIEWPORT_PADDING
  })
]));

const { placement, floatingStyles, middlewareData } = useFloating(target, tooltip, {
  placement: preferredPlacement,
  middleware: middleware,
  whileElementsMounted: autoUpdate
});

const sidePlacement = computed(() => getSidePlacement(placement?.value));

const arrowStyles  = computed(() => {
  const { x, y } = (middlewareData?.value?.arrow ?? {});
  return {
    left: x != null ? `${x}px` : '',
    top: y != null ? `${y}px` : '',
  };
});

onMounted(() => {
  host.value = slot.value.getRootNode().host;

  if (target.value) {
    addListeners(target.value);
  }
});

onUnmounted(() => {
  if (target.value) {
    removeListeners(target.value);
  }
});

watch(() => props.disabled, () => {
  if (props.disabled) {
    onDisabled();
  }
});

watch(target, (newTarget, oldTarget) => {
  if (oldTarget) {
    removeListeners(oldTarget);
  }
  if (newTarget) {
    addListeners(newTarget);
  }
});

function getPlacement(placement) {
  return AVAILABLE_PLACEMENTS.includes(placement) ? placement : DEFAULT_PLACEMENT;
}

function getSidePlacement(placement) {
  return getPlacement(placement).split('-')[0];
}

function getFallbackPlacements(placement) {
  switch (getSidePlacement(placement)) {
    case 'top':
      return ['bottom', 'right', 'left'];
    case 'bottom':
      return ['top', 'left', 'right'];
    case 'left':
      return ['right', 'top', 'bottom'];
    case 'right':
      return ['left', 'bottom', 'top'];
  }
}

function getTarget(target, host) {
  if (target instanceof HTMLElement) {
    return target;
  }

  if (target === 'parent') {
    return host?.parentElement;
  }

  return document.getElementById(target);
}

function isTargetDisabled(target) {
  return target?.disabled || target?.classList.contains('disabled');
}

function addListeners(target) {
  target.addEventListener('mouseenter', onEnter);
  target.addEventListener('mouseleave', onLeave);

  target.addEventListener('focusin', onEnter);
  target.addEventListener('focusout', onLeave);

  targetObserver.observe(target, { attributeFilter: ['class', 'disabled'] });
}

function removeListeners(target) {
  target.removeEventListener('mouseenter', onEnter);
  target.removeEventListener('mouseleave', onLeave);

  target.removeEventListener('focusin', onEnter);
  target.removeEventListener('focusout', onLeave);

  targetObserver.disconnect();
}

async function onEnter(event) {
  if (props.disabled) {
    return;
  }

  triggers.handleEvent(event);

  if (visible.value) {
    return;
  }

  visible.value = true;

  await nextTick();

  content.fromSlot(slot.value);
  content.appendTo(tooltipContent.value);
}

function onLeave(event) {
  triggers.handleEvent(event);

  if (triggers.isActive()) {
    return;
  }

  visible.value = false;
}

function onAfterLeave() {
  if (visible.value) {
    return;
  }

  content.appendTo(host.value);
  content.reset();
}

function onDisabled() {
  visible.value = false;
  triggers.deactivate();
}
</script>

<template>
  <slot ref="slot"></slot>
  <Teleport to="#rp-tooltips">
    <Transition name="rp-tooltip" @after-leave="onAfterLeave">
      <div ref="tooltip" class="rp-tooltip" :class="[style]" v-if="visible" :data-placement="sidePlacement" :style="floatingStyles">
        <div ref="tooltipArrow" class="rp-tooltip-arrow" :style="arrowStyles"></div>
        <div ref="tooltipContent" class="rp-tooltip-content"></div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
  :host {
    display: none;
  }
</style>
