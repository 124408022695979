import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import Router from 'core/router';
import $ from 'jquery';
import { copyToClipboard, formatAttrSelector } from 'utils/functions';
import Alerts from './alerts';
import Validation from './validation';

const MODAL_LOAD = '#load-table-view-modal';
const MODAL_SAVE = '#save-table-view-modal';

const SELECT_LOAD = '#load-table-view-select';
const SELECT_SAVE = '#save-table-view-select';

const BUTTON_LOAD = '#load-table-view-button';
const BUTTON_SAVE = '#save-table-view-button';
const BUTTON_SHARE = '.share-table-view-button';

const ALERTS = '.modal-footer-alerts';
const ALERT_OVERWRITE = 'overwrite-table-view-alert';

const ATTR_TABLE = 'data-table';
const ATTR_FILTER = 'data-filter';
const ATTR_CS_TABLE = 'data-cs-table';

function createTableViewUrl(viewId) {
  const url = new URL(window.location.href);

  if (viewId) {
    url.searchParams.set('view', viewId);
  } else {
    url.searchParams.delete('view');
  }

  return url;
}

class TableViewData {
  constructor($element) {
    this.tableId = $element.attr(ATTR_TABLE);
    this.filterId = $element.attr(ATTR_FILTER);
    this.csTableId = $element.attr(ATTR_CS_TABLE);

    if (this.tableId) {
      this.table = $('.rp-table' + formatAttrSelector('data-table-id', this.tableId)).data('rp-table');
    }

    if (this.filterId) {
      this.filter = $('.rp-filter' + formatAttrSelector('data-filter-id', this.filterId)).data('rp-filter');
    }
  }

  getData() {
    let data = {
      'table': this.tableId,
      'filter': this.filterId,
      'cs-table': this.csTableId
    };

    if (this.table) {
      data = Object.assign(data, this.table.getTableState());
    }

    if (this.filter) {
      data = Object.assign(data, this.filter.getFilterState());
    }

    return data;
  }
}

class TableViewModal extends TableViewData {
  constructor($modal, selectId, buttonId) {
    super($modal.find('form'));

    this.$modal = $modal;
    this.$form = $modal.find('form');

    this.$select = $modal.find(selectId);
    this.$button = $modal.find(buttonId);

    this.bindEvents();
  }

  bindEvents() {
    this.$button.on('click', event => {
      event.preventDefault();

      if (Validation.validate(this.$form)) {
        this.$button.loading();
        this.handleClick();
      }
    });
  }

  handleClick() {
    throw new Error('This method should be implemented');
  }
}

class LoadTableViewModal extends TableViewModal {
  constructor($modal) {
    super($modal, SELECT_LOAD, BUTTON_LOAD);
  }

  handleClick() {
    window.location.replace(createTableViewUrl(this.$select.val()));
  }
}

class SaveTableViewModal extends TableViewModal {
  constructor($modal) {
    super($modal, SELECT_SAVE, BUTTON_SAVE);
    this.$alerts = $modal.find(ALERTS);

    this.toggleAlert(true);
  }

  bindEvents() {
    super.bindEvents();

    this.$select.on('change', () => this.toggleAlert(this.isNew()));
  }

  handleClick() {
    let request;
    if (this.isNew()) {
      request = Fetch.post(Router.get('API_TABLE_VIEW_CREATE'), Fetch.toFormData({
        'name': this.$select.val(),
        ...this.getData()
      }));
    } else {
      request = Fetch.post(Router.get('API_TABLE_VIEW_UPDATE', {
        'id': this.$select.val()
      }), Fetch.toFormData(this.getData()));
    }

    request
      .then(response => {
        if (response.ok) {
          Alerts.store('success', I18N.t('table_view_save_success'));
          window.location.replace(createTableViewUrl(response.body['viewId']));
        }
      })
      .catch(error => Log.error(error));
  }

  isNew() {
    return this.$select.find('option:selected').is(formatAttrSelector('data-select2-tag', 'true'));
  }

  toggleAlert(hidden) {
    if (hidden) {
      if (this.$alert == null) {
        this.$alert = this.$alerts.find(formatAttrSelector('data-alert-id', ALERT_OVERWRITE)).detach();
      }
    } else {
      if (this.$alert != null) {
        this.$alerts.append(this.$alert);
      }
      this.$alert = null;
    }
  }
}

class ShareTableView extends TableViewData {
  constructor($button) {
    super($button);
    this.$button = $button;

    this.handle();
  }

  handle() {
    this.$button.loading();

    Fetch.post(Router.get('API_TABLE_VIEW_SHARE'), Fetch.toFormData(this.getData()))
      .then(response => {
        if (response.ok) {
          this.$button.loading(false);
          copyToClipboard(createTableViewUrl(response.body['viewId']));
          Alerts.success(I18N.t('table_view_share_copy_success'));
        }
      })
      .catch(error => Log.error(error));
  }
}

$(()=> {
  window.history.replaceState(null, null, createTableViewUrl());

  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is(MODAL_LOAD)) {
      new LoadTableViewModal(data.$root);
    }
    if (data.$root.is(MODAL_SAVE)) {
      new SaveTableViewModal(data.$root);
    }
  });

  $(document).on('click', BUTTON_SHARE, event => {
    event.preventDefault();

    new ShareTableView($(event.currentTarget));
  });
});
