import Fetch from 'core/fetch';
import I18N from 'core/i18n';
import Log from 'core/log';
import Router from 'core/router';
import $ from 'jquery';
import { copyToClipboard } from 'utils/functions';

class CustomersNewsletterModalForm {
  constructor($modal) {
    this.$copyButton = $modal.find('#customers-newsletter-copy');
    this.$emailsContent = $modal.find('#customers-newsletter-emails');
    this.bindEvents();
  }

  bindEvents() {
    this.$copyButton.on('click', () => {
      copyToClipboard(this.$emailsContent.val());
    });
  }
}

class CustomersNewsletterForDeals {
  constructor() {
    this.$emails = $('#customers-newsletter-for-deals-emails');
    this.$control = this.$emails.closest('.form-group');
    this.$help = this.$control.find('.form-help');
    this.$copy = $('#customers-newsletter-for-deals-copy');
    this.$empty = $('#customers-newsletter-for-deals-empty');

    if (this.$emails.exists()) {
      this.bindEvents();
    }
  }

  bindEvents() {
    $(document).on('rp-filter:filter', (event, data) => {
      Fetch.post(Router.get('API_CUSTOMERS_NEWSLETTER_FOR_DEAL'), data.values)
        .then(response => {
          if (response.ok) {
            const emails = response.body['emails'];

            this.$emails.val(emails);
            this.$help.text(response.body['result_count'] + ' ' + I18N.t('records_count'));

            this.$control.hidden(!emails);
            this.$empty.hidden(!!emails);
          }
        })
        .catch(error => Log.error(error));
    });

    this.$copy.on('click', () => {
      copyToClipboard(this.$emails.val());
    });
  }
}

$(()=> {
  new CustomersNewsletterForDeals();

  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is('#customers-newsletter-modal-form')) {
      new CustomersNewsletterModalForm(data.$root);
    }
  });
});
