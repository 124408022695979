import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/bg';
import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/hu';
import 'dayjs/locale/pl';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sk';
import 'dayjs/locale/sl';
import 'dayjs/locale/uk';

dayjs.extend(customParseFormat);
dayjs.locale(document.documentElement.lang);

const DATE_FORMAT = 'YYYY-MM-DD';

export default class Dates {
  static now() {
    return dayjs();
  }

  static format(value, format = DATE_FORMAT) {
    const parsed = this.parse(value);
    if (parsed) {
      return parsed.format(format);
    }

    return value;
  }

  static parse(value, format = DATE_FORMAT) {
    if (value instanceof dayjs) {
      return value;
    }

    if (value instanceof Date) {
      return dayjs(value);
    }

    if (typeof value !== 'string') {
      return null;
    }

    const parsed = dayjs(value, format, true);
    if (parsed.isValid()) {
      return parsed;
    }

    return null;
  }
}
