import $ from 'jquery';
import { copyToClipboard } from '../utils/functions';

class UserMenu {
  constructor() {
    this.$developerName = $('.dropdown-developer-name');
    this.$developerId = $('.dropdown-developer-id');

    this.developerName = this.$developerName.attr('data-developer-name');
    this.developerId = this.$developerId.attr('data-developer-id');

    this.bindEvents();
  }

  bindEvents() {
    this.$developerName.on('click', () => copyToClipboard(this.developerName));
    this.$developerId.on('click', () => copyToClipboard(this.developerId));
  }
}

$(() => {
  new UserMenu();
})